<template>
  <div id="no-content-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-journal-x"></i>
          <span style="margin-left: 5px"> No Content </span>
        </b-card-title>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <b-button variant="outline-success" @click="exportData()">
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- date filter -->
          <dateRangePicker
            :from_date="filter_data.from_date"
            :to_date="filter_data.to_date"
            @update-value="dateFilterChanged"
          />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge " style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter_data.key"
              placeholder="Masukkan kata kunci..."
            />
          </b-input-group>
        </div>
      </b-card-header>
      <div>
        <!-- loader component -->
        <loader v-if="is_loading" />
        <!-- dataTable -->
        <dataTable
          v-else
          :data="table_data"
          :is_row_clicked="true"
          :is_pagination="false"
          :viewDetail="viewDetail"
        />
        <paginationInfo
          :page="pagination.page"
          :items="pagination.items"
          :count="pagination.count"
          @update-value="paginationChanged"
        />
      </div>
    </b-card>
    <!-- detail modal component -->
    <detailNoContentModal ref="detail" />
  </div>
</template>
<script>
import moment from "moment";
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import loader from "@/views/components/loader.vue";
import dateRangePicker from "@/views/components/dateRangePicker.vue";
import detailNoContentModal from "@/views/components/no-content/detailNoContentModal.vue";
import axios from "axios";

export default {
  name: "NoContentLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    paginationInfo,
    dataTable,
    loader,
    dateRangePicker,
    detailNoContentModal,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        key: "",
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "table-header text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            thClass: "table-header text-left",
            tdClass: "text-left",
          },
          {
            key: "count",
            label: "jumlah berita",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    dateFilterChanged(data) {
      this.filter_data.from_date = data.from_date;
      this.filter_data.to_date = data.to_date;
      this.getData();
    },
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    exportData() {
      const params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      const url = `${process.env.VUE_APP_API_URL}stream/no-content/export?${query}`;
      window.open(url);
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        page: this.pagination.page,
        items: this.pagination.items,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "stream/no-content?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data?.no_content || [];
          this.pagination.count = res.data?.page_info[0]?.total || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    viewDetail(id) {
      this.$bvModal.show("modal-detail-no-content");
      this.$refs.detail.getData(id);
    },
  },
};
</script>
